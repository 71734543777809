import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { statusColorBackgrounds, statusColorTexts } from '@nuis/common';
import { SidebarItem } from '../models/sidebar-element.model';

@Component({
    selector: 'luis-sidebar-item',
    standalone: true,
    imports: [NgClass, RouterLink, RouterLinkActive, TranslatePipe],
    template: `
        <a
            class="block text-700 p-2 hover:surface-200 border-round no-underline"
            [routerLink]="item().route"
            routerLinkActive="surface-200 text-900">
            <i
                class="mr-2 p-2 border-round"
                [ngClass]="[
                    item().icon,
                    backgroundClasses[item().color ?? 'gray'],
                    textClasses[item().color ?? 'gray'],
                ]"></i>

            {{ item().displayName | translate }}</a
        >
    `,
})
export class SidebarItemComponent {
    public item = input.required<SidebarItem>();

    protected backgroundClasses = statusColorBackgrounds;
    protected textClasses = statusColorTexts;
}
