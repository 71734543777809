import { Route } from '@angular/router';
import { claimDetailsRoute } from '@nuis/travel';

export const travelClaimDetailsRoutes: Route = {
    loadComponent: () => import('./claim-details.component'),
    title: 'claimDetails',
    children: [
        { path: '', redirectTo: 'overview', pathMatch: 'full' },
        { path: '', ...claimDetailsRoute },
        {
            path: 'overview',
            loadComponent: () => import('./pages/overview/claim-details-overview.component'),
        },
        {
            path: 'audits',
            loadComponent: () => import('./pages/audits/claim-details-audits.component'),
        },
    ],
};
