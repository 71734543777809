import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAccessComponent } from '@luis/common/shared';
import { coreRoutes } from '@nuis/core';
import { travelRoutes } from '@nuis/travel';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        ...coreRoutes,
    },
    {
        path: 'travel-insurance',
        ...travelRoutes,
    },
    {
        path: 'travel-insurance',
        loadChildren: () => import('./products/travel/travel.module').then((m) => m.TravelModule),
    },
    {
        path: 'notifications/:notificationId',
        loadComponent: () => import('@luis/luis/notification').then((l) => l.NotificationDetailsComponent),
    },
    {
        path: 'consistency-checks',
        loadChildren: () => import('@luis/dynamic/consistency-checks').then((l) => l.DynamicConsistencyChecksModule),
    },
    {
        path: 'dashboard',
        loadComponent: () => import('./common/dashboard/dashboard.component').then((l) => l.DashboardComponent),
    },
    {
        path: 'no-access',
        component: NoAccessComponent,
    },
    {
        path: '404',
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        redirectTo: '404',
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            paramsInheritanceStrategy: 'always',
            bindToComponentInputs: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
