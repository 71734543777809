import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class StartupService {
    public async load(injector: Injector) {
        this.initTranslations(injector);
        this.initLocale();
    }

    private initTranslations(injector: Injector) {
        const translateService = injector.get(TranslateService);
        translateService.use('en');
    }

    private initLocale() {
        registerLocaleData(localeDe);
    }
}
