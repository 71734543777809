import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonStateService, ThemeService } from '@luis/common/shared';
import { NavbarService, SidebarType } from '@luis/luis/navigation';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, switchMap, tap } from 'rxjs';

@Component({
    selector: 'luis-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private readonly theme = inject(ThemeService);
    private readonly navbar = inject(NavbarService);
    private readonly translate = inject(TranslateService);
    private readonly primeNgConfig = inject(PrimeNGConfig);
    private readonly commonStateService = inject(CommonStateService);
    private readonly destroyRef = inject(DestroyRef);

    protected selectedSidebarType$: Observable<SidebarType>;

    public ngOnInit() {
        this.primeNgConfig.ripple = true;

        this.selectedSidebarType$ = this.navbar.selectedSidebarType$;

        this.commonStateService.setPartnerIds();

        this.navbar.selectedSidebarLanguage$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                switchMap((language) => this.changeLanguage(language.code)),
            )
            .subscribe();
    }

    public changeTheme() {
        this.theme.switchTheme();
    }

    private changeLanguage(lang: string): Observable<void> {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);

        return this.translate.get('primeng').pipe(tap((res) => this.primeNgConfig.setTranslation(res)));
    }
}
