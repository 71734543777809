import { Component, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent, SidebarComponent, SidebarElement } from '@luis/luis/navigation';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'luis-root',
    standalone: true,
    imports: [RouterOutlet, ToastModule, SidebarComponent, NavbarComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    protected sidebarItems = signal<SidebarElement[]>([
        {
            type: 'item',
            displayName: 'dashboard',
            route: ['dashboard'],
            icon: 'pi pi-home',
        },
        {
            type: 'group',
            displayName: 'travel-insurance',
            productCategory: 'TravelInsurance',

            children: [
                {
                    type: 'item',
                    displayName: 'contracts',
                    route: ['/travel-insurance', 'contracts'],
                    icon: 'pi pi-table',
                    color: 'green',
                    permission: 'read_contract',
                },
                {
                    type: 'item',
                    displayName: 'claims',
                    route: ['/travel-insurance', 'claims'],
                    icon: 'pi pi-shield',
                    color: 'purple',
                    permission: 'read_claim',
                },
                {
                    type: 'item',
                    displayName: 'travels',
                    route: ['/travel-insurance', 'travels'],
                    icon: 'pi pi-compass',
                    color: 'blue',
                    permission: 'read_contract',
                },
                {
                    type: 'item',
                    displayName: 'organizations',
                    route: ['/travel-insurance', 'organizations'],
                    icon: 'pi pi-building',
                    permission: 'read_contract',
                },
                {
                    type: 'item',
                    displayName: 'travel-warnings',
                    route: ['/travel-insurance', 'travel-warnings'],
                    icon: 'pi pi-map',
                    permission: 'read_contract',
                },
                {
                    type: 'item',
                    displayName: 'consistency-checks',
                    route: ['/travel-insurance', 'consistency-checks'],
                    icon: 'pi pi-exclamation-circle',
                    permission: 'read_contract',
                },
                {
                    type: 'item',
                    displayName: 'sidebar.base.openReversals',
                    route: ['/travel-insurance', 'open-reversals'],
                    icon: 'pi pi-reply',
                    permission: 'approve_refund_transaction',
                },
            ],
        },
        {
            type: 'group',
            displayName: 'sidebar.base.general',
            productCategory: 'TravelInsurance',

            children: [
                {
                    type: 'item',
                    displayName: 'sidebar.base.notifications',
                    route: ['/notifications'],
                    icon: 'pi pi-envelope',
                    permission: 'read_contract',
                },
            ],
        },
    ]);
}
