import { Routes } from '@angular/router';
import { travelClaimDetailsRoutes } from '@luis/travel/claim-details';
import { coreRoutes } from '@nuis/core';
import { travelRoutes } from '@nuis/travel';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        ...coreRoutes,
    },
    {
        path: 'travel-insurance',
        ...travelRoutes,
    },
    {
        path: 'travel-insurance',
        children: [
            { path: '', redirectTo: 'contracts', pathMatch: 'full' },
            {
                path: 'contracts/:contractId',
                loadChildren: () =>
                    import('@luis/dynamic/contract-details').then((l) => l.DynamicContractDetailsModule),
                title: 'contractDetails',
            },
            {
                path: 'organizations/:organizationId',
                loadChildren: () => import('@luis/core/organization').then((m) => m.CoreOrganizationModule),
                title: 'frameworkContracts',
            },
            {
                path: 'claims/:claimId',
                ...travelClaimDetailsRoutes,
            },
            {
                path: 'travels/:travelId',
                loadChildren: () => import('@luis/dynamic/travel-details').then((l) => l.DynamicTravelDetailsModule),
                title: 'travelDetails',
            },
            {
                path: 'consistency-checks',
                loadChildren: () =>
                    import('@luis/dynamic/consistency-checks').then((m) => m.DynamicTravelConsistencyChecksModule),
            },
        ],
    },
    {
        path: 'consistency-checks',
        loadChildren: () => import('@luis/dynamic/consistency-checks').then((l) => l.DynamicConsistencyChecksModule),
    },
    {
        path: 'dashboard',
        loadComponent: () => import('./common/dashboard/dashboard.component').then((l) => l.DashboardComponent),
        title: 'dashboard',
    },
    {
        path: 'no-access',
        loadComponent: () => import('@nuis/common').then((c) => c.NoAccessComponent),
    },
    {
        path: '404',
        loadComponent: () => import('@nuis/common').then((c) => c.PageNotFoundComponent),
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
