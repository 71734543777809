<div class="h-full surface-section border-right-1 surface-border">
    <div class="flex flex-column h-full overflow-y-auto">
        <a class="flex py-3 justify-content-center" [routerLink]="['/']">
            <img src="shared/images/logo-luis-quer.svg" alt="LUIS" height="90vh" />
        </a>

        <div class="flex flex-column gap-3 p-2">
            @for (item of items(); track item) {
                @switch (item.type) {
                    @case ('group') {
                        <luis-sidebar-group *hasPermission="null; product: item.productCategory" [group]="item" />
                    }
                    @case ('item') {
                        <luis-sidebar-item *hasPermission="item.permission" [item]="item" />
                    }
                }
            }
        </div>
    </div>
</div>
