import { Component, input } from '@angular/core';
import { HasPermissionDirective } from '@luis/common/shared';
import { TranslatePipe } from '@ngx-translate/core';
import { SidebarGroup } from '../models/sidebar-element.model';
import { SidebarItemComponent } from './sidebar-item.component';

@Component({
    selector: 'luis-sidebar-group',
    standalone: true,
    imports: [TranslatePipe, HasPermissionDirective, SidebarItemComponent],
    template: `
        <div *hasPermission="null; product: group().productCategory" class="flex flex-column">
            <div class="text-900 py-3 px-2 uppercase font-bold">
                {{ group().displayName | translate }}
            </div>

            <div class="flex flex-column gap-1">
                @for (item of group().children; track item) {
                    <luis-sidebar-item [item]="item" />
                }
            </div>
        </div>
    `,
})
export class SidebarGroupComponent {
    public group = input.required<SidebarGroup>();
}
