import { Routes } from '@angular/router';
import { coreRoutes } from '@nuis/core';
import { travelRoutes } from '@nuis/travel';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        ...coreRoutes,
    },
    {
        path: 'travel-insurance',
        ...travelRoutes,
    },
    {
        path: 'dashboard',
        loadComponent: () => import('./common/dashboard/dashboard.component').then((l) => l.DashboardComponent),
        title: 'dashboard',
    },
    {
        path: 'no-access',
        loadComponent: () => import('@nuis/common').then((c) => c.NoAccessComponent),
    },
    {
        path: '404',
        loadComponent: () => import('@nuis/common').then((c) => c.PageNotFoundComponent),
    },
    {
        path: '**',
        redirectTo: '404',
    },
];
