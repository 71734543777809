import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, EnvironmentProviders, Injectable, Injector, makeEnvironmentProviders } from '@angular/core';
import { LanguageService, ThemeService } from '@luis/common/shared';

export function provideStartupService(): EnvironmentProviders {
    return makeEnvironmentProviders([
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: (startupService, injector) => () => startupService.load(injector),
            deps: [StartupService, Injector],
            multi: true,
        },
    ]);
}

@Injectable()
class StartupService {
    public async load(injector: Injector) {
        this.initTranslations(injector);
        this.initLocale();
    }

    private initTranslations(injector: Injector) {
        const languageService = injector.get(LanguageService);
        languageService.init();

        const themeService = injector.get(ThemeService);
        themeService.init();
    }

    private initLocale() {
        registerLocaleData(localeDe);
    }
}
