<div class="px-2 surface-section border-bottom-1 surface-border" style="height: 55px">
    <div class="flex flex-row align-items-center justify-content-between">
        <p-button
            [label]="'products' | translate"
            [text]="true"
            severity="secondary"
            icon="pi pi-chevron-down"
            (onClick)="productsPanel.toggle($event)" />

        @if (user(); as user) {
            <p-button
                styleClass="flex gap-2 p-2"
                [text]="true"
                severity="secondary"
                (onClick)="settingsPanel.toggle($event)">
                <nuis-profile-picture [email]="user.email" />
                <div>{{ user.firstname }} {{ user.lastname }}</div>
            </p-button>
        }
    </div>
</div>

<p-overlayPanel #settingsPanel>
    <div class="flex flex-column w-15rem">
        <div class="mb-4 text-xl">{{ 'sidebar.settings.header' | translate }}</div>

        <div class="flex flex-column gap-4">
            <div class="flex flex-column gap-2">
                <div>{{ 'sidebar.settings.language' | translate }}</div>

                <p-dropdown
                    styleClass="w-full"
                    [options]="languages"
                    optionLabel="name"
                    optionValue="code"
                    [ngModel]="selectedLanguage()"
                    (ngModelChange)="setLanguage($event)">
                    <ng-template let-lang pTemplate="item">
                        <div class="flex gap-2 align-items-center">
                            <i [ngClass]="lang.flag"></i>
                            <div>{{ lang.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-lang pTemplate="selectedItem">
                        <div class="flex gap-2 align-items-center">
                            <i [ngClass]="lang.flag"></i>
                            <div>{{ lang.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>

            <div class="flex flex-column gap-2">
                <div>{{ 'sidebar.settings.theme' | translate }}</div>

                <div class="flex gap-2">
                    <button
                        class="flex-1 bg-white text-gray-900 flex flex-column gap-2 align-items-center p-3 border-3 border-round hover:shadow-4 cursor-pointer"
                        [ngClass]="{
                            'border-transparent': selectedTheme() !== 'lara-light-blue',
                            'shadow-2 border-700': selectedTheme() === 'lara-light-blue',
                        }"
                        (click)="setTheme('lara-light-blue')">
                        <i class="pi pi-sun text-4xl"></i>
                        <div class="text-base">{{ 'lightMode' | translate }}</div>
                    </button>

                    <button
                        class="flex-1 bg-gray-900 text-gray-50 flex flex-column gap-2 align-items-center p-3 border-3 border-round hover:shadow-4 cursor-pointer"
                        [ngClass]="{
                            'border-transparent': selectedTheme() !== 'lara-dark-blue',
                            'shadow-2 border-700': selectedTheme() === 'lara-dark-blue',
                        }"
                        (click)="setTheme('lara-dark-blue')">
                        <i class="pi pi-moon text-4xl"></i>
                        <div class="text-base">{{ 'darkMode' | translate }}</div>
                    </button>
                </div>
            </div>

            <p-divider />

            <div class="flex justify-content-center">
                <p-button
                    [label]="'sidebar.logout' | translate"
                    icon="pi pi-power-off"
                    [severity]="'danger'"
                    [text]="true"
                    (onClick)="logout()" />
            </div>
        </div>
    </div>
</p-overlayPanel>

<p-overlayPanel #productsPanel>
    <div class="flex gap-3">
        <div class="w-6 flex flex-column gap-2">
            <div class="p-3 surface-50 text-xl">{{ 'product.luis' | translate }}</div>

            @for (product of luisProducts(); track $index) {
                <a
                    pRipple
                    class="flex p-3 align-items-center cursor-pointer hover:surface-200 border-round no-underline text-900"
                    [href]="product.link"
                    target="_blank">
                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                        <i class="text-2xl" [ngClass]="product.icon"></i>
                    </div>
                    <div>
                        <span>{{ product.title | translate }}</span>
                        <div class="text-600 text-sm mt-1">{{ product.description }}</div>
                    </div>
                </a>
            }
        </div>

        <div class="w-6 flex flex-column gap-2">
            <div class="p-3 surface-50 text-xl">{{ 'product.crm' | translate }}</div>

            @for (product of crmProducts(); track $index) {
                <a
                    pRipple
                    class="flex p-3 align-items-center cursor-pointer hover:surface-200 border-round no-underline text-900"
                    [href]="product.link"
                    target="_blank">
                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                        <i class="text-2xl" [ngClass]="product.icon"></i>
                    </div>
                    <div>
                        <span>{{ product.title | translate }}</span>
                        <div class="text-600 text-sm mt-1">{{ product.description }}</div>
                    </div>
                </a>
            }
        </div>
    </div>
</p-overlayPanel>
