<p-toast />

<div class="flex h-screen w-screen">
    <div class="h-screen w-18rem">
        @if (selectedSidebarType$ | async; as response) {
            @switch (response.code) {
                @case ('PM') {
                    <nav-sidebar-productmenu />
                }
                @case ('ST') {
                    <nav-sidebar-stacked />
                }
                @case ('IC') {
                    <nav-sidebar-icons />
                }
            }
        }
    </div>

    <div class="flex flex-column h-screen flex-1 overflow-hidden">
        <nav-navbar class="z-1" />

        <div class="flex-1 overflow-hidden">
            <router-outlet />
        </div>
    </div>
</div>
