import { Configuration, KEYCLOAK_CONFIGURATION_DEVELOPMENT, PARTNER_IDS_DEVELOPMENT } from '@luis/common/shared';

export const environment: Configuration = {
    production: false,
    baseUrl: 'https://dev.lamie.systems/ui/api/v1/',
    bffBaseUrl: 'https://dev.lamie.systems/luis-gateway/api/v1/',
    subscriptionKey: '58fd919c-0690-11ed-b939-0242ac120002',
    partnerIds: PARTNER_IDS_DEVELOPMENT,
    keycloak: KEYCLOAK_CONFIGURATION_DEVELOPMENT,
    logging: false,
};
