import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, inject, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService, RolesService, SharedModule, StateInfo } from '@luis/common/shared';
import { NavigationModule } from '@luis/luis/navigation';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { HumanizeFilesizePipe } from '@nuis/common';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { StartupService } from './startup.service';

const MultiHttpLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
    return new MultiTranslateHttpLoader(http, ['./i18n/', './shared/i18n/']);
};

@NgModule({
    declarations: [AppComponent, PageNotFoundComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule.forRoot(environment),
        NavigationModule,
        BrowserAnimationsModule,
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: MultiHttpLoaderFactory,
                deps: [HttpBackend],
            },
            isolate: false,
            extend: true,
        }),

        DialogService,
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: (startupService, injector) => () => startupService.load(injector),
            deps: [StartupService, Injector],
            multi: true,
        },

        CurrencyPipe, // NOTE: Used in money.pipe.ts
        DecimalPipe, // NOTE: Used in money.pipe.ts
        HumanizeFilesizePipe,
    ],
})
export class AppModule implements DoBootstrap {
    private readonly auth = inject(AuthService);
    private readonly rolesService = inject(RolesService);

    public ngDoBootstrap(appRef: ApplicationRef): void {
        appRef.bootstrap(AppComponent);

        this.authenticate();
    }

    private authenticate() {
        const state: StateInfo = {
            debug: {
                showToken: true,
                showUserInfo: true,
                showClient: true,
            },
        };
        this.auth.init(state);
        this.rolesService.init();
    }
}
